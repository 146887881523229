.accordion {
    max-width: 600px;
    margin: 2rem auto;
}

.accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background-color: var(--secondary-bg-color);
}

.accordion-title:hover {
    background-color: var(--hover-bg-color);
}

.accordion-title,
.accordion-content {
    padding: 1rem;
}

.accordion-content {
    background-color: var(--hover-bg-color);
}

.accordion-item.active .accordion-title {
    background-color: var(--hover-bg-color);
}

@media screen and (max-width: 700px) {
    .accordion {
        width: 90%;
    }
}