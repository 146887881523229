@media (max-width: 991px) {
    .SiteHeader {
        flex-direction: column;
        padding-top: 2rem;
    }
    .SiteHeader-branding {
        text-align: center;
    }

    .SiteHeader-nav ul {
        justify-content: center;
    }

    .SiteHeader-nav {
        margin-top: 1rem;
    }

    .content-wrapper {
        top: 216px !important;
        padding-bottom: 216px !important;
    }

    #faqWrapper {
        padding-top: 50px !important;
    }
}
