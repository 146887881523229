/* Toast.css */
:root {
    --toast-bg-color: #5a5a5a;
    --toast-text-color: #fff;
    --error-color: #f5493d;
    --success-color: #4eb151;
    --info-color: #2597f4;
}

[data-theme="light"] {
    --toast-bg-color: #d1d1d1;
    --toast-text-color: #000;
}

.toast-container {
    --button-ok-bg: #404040;
    --button-ok-hover-bg: #333333;
    --button-cancel-bg: #4d4d4d;
    --button-cancel-hover-bg: #404040;
    --button-ok-text: white;
    --button-cancel-text: white;
}

.toast-container.error {
    --button-ok-bg: #da190b;
    --button-ok-hover-bg: #c2160a;
    --button-cancel-bg: #f21c0d;
    --button-cancel-hover-bg: #da190b;
    --button-ok-text: white;
    --button-cancel-text: white;
}

.toast-container.success {
    --button-ok-bg: #3e8e41;
    --button-ok-hover-bg: #367c39;
    --button-cancel-bg: #46a049;
    --button-cancel-hover-bg: #3e8e41;
    --button-ok-text: white;
    --button-cancel-text: white;
}

.toast-container.info {
    --button-ok-bg: #0b7dda;
    --button-ok-hover-bg: #0a6fc2;
    --button-cancel-bg: #0d8bf2;
    --button-cancel-hover-bg: #0b7dda;
    --button-ok-text: white;
    --button-cancel-text: white;
}

.toast-container.error,
.toast-container.success,
.toast-container.info {
    --toast-bg-color: var(--error-color);
}

.toast-container.success {
    --toast-bg-color: var(--success-color);
}

.toast-container.info {
    --toast-bg-color: var(--info-color);
}

.toast-container {
    top: 0;
    margin-top: 1rem;
    z-index: 1100;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
}

.toast-container {
    display: none;
}

.toast-container.show {
    display: flex;
}

.toast-container.show .toast {
    font-size: initial !important;
    opacity: 1;
    z-index: 2;
}

.toast-container .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.toast-container .toast-body {
    background-color: var(--toast-bg-color);
    color: var(--toast-text-color);
}

.toast-container .toast-header {
    background-color: var(--primary-bg-color);
    color: var(--primary-text-color);
}

.toast-container .toast-body,
.toast-container .toast-header,
.toast-container .toast-footer {
    color: var(--toast-text-color);
}

.toast-container .toast-body,
.toast-container .toast-header,
.toast-container .toast-footer {
    background-color: var(--toast-bg-color);
}

.toast-container .close {
    margin-right: -5px;
    display: flex;
}

.toast-container .toast-footer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.toast-container .btn-ok,
.toast-container .btn-cancel {
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
}

.toast-container button:focus {
    outline: 1px dotted;
}

.toast-container .btn-ok {
    background-color: var(--button-ok-bg);
    color: var(--button-ok-text);
}

.toast-container .btn-cancel {
    background-color: var(--button-cancel-bg);
    color: var(--button-cancel-text);
}

.toast-container .btn-ok:hover {
    background-color: var(--button-ok-hover-bg);
}

.toast-container .btn-cancel:hover {
    background-color: var(--button-cancel-hover-bg);
}
