.sampleInstructions {
    width: 100%;
    font-weight: bold;
}

.sampleContent {
    width: 100%;
    font-weight: bold;
}

.sampleDrafts {
    width: 100%;
    font-weight: bold;
}