.App {
    text-align: left;
    padding: 0 10% 2%;
    min-height: 100vh;
    color: var(--primary-text-color);
}

.App-main {
    margin-top: 20vh;
    font-size: calc(10px + 1vmin);
}
