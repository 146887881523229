/* Default (Dark Mode) CSS Variables */
:root {
    --primary-bg-color: #282c34;
    --primary-text-color: white;
    --link-color: #61dafb;
    --secondary-bg-color: #2f333b;
    --hover-bg-color: #373c44;
}

/* Light Mode CSS Variables */
[data-theme="light"] {
    --primary-bg-color: white;
    --primary-text-color: black;
    --link-color: #282c34;
    --secondary-bg-color: #d1d1d1;
    --hover-bg-color: #e6e6e6;
}

[data-theme="light"] {
    --primary-bg-color: white;
    --primary-text-color: black;
    --link-color: #282c34;
    --secondary-bg-color: #d1d1d1;
    --hover-bg-color: #e6e6e6;
}

body {
    color: var(--primary-text-color);
}

.WordcraftStudios {
    text-align: left;
    padding: 0 10% 2%;
    min-height: 100vh;
    /* background-color: var(--primary-bg-color); */
    color: var(--primary-text-color);
}

.WordcraftStudios-main {
    margin-top: 30px;
    font-size: calc(10px + 1vmin);
    position: relative;
    z-index: 1;
}
.background-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}

.background-wrapper::before {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    z-index: -2;
}

.background-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: -1;
}

.content-wrapper {
    z-index: 1;
    font-weight: 500;
    position: relative;
    overflow-y: auto;
    top: 0;
    width: 100%;
    height: 100vh;
    margin-bottom: 50px;
    top: 160px;
    padding-bottom: 160px;
}

.white-bg {
    background-color: rgba(255, 255, 255, 0.8) !important;
    color: var(--primary-text-color) !important;
    border-radius: 20px !important;
    padding: 35px !important;
}

.black-bg {
    background-color: rgba(0, 0, 0, 0.6) !important;
    color: white !important;
    border-radius: 20px !important;
    padding: 35px !important;
}

.black-bg .form-control {
    background-color: #080808 !important;
    color: white !important;
    border-color: #080808 !important;
    border: 1px solid #080808 !important;
}

.black-bg input:-webkit-autofill,
.black-bg input:-webkit-autofill:hover,
.black-bg input:-webkit-autofill:focus,
.black-bg input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 0px 1000px #080808 !important;
}
